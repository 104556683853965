<template>
  <div class="loader-wrapper--full">
    <div class="loader-overlay"></div>
    <div class="loader"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #dba822;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.loader-wrapper--full {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}

.loader-overlay {
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.33;
  position: fixed;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
