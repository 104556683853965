const obj = {
  apiUrl: "https://apidevmymd21-5701.rostiapp.cz/api/v1.0",
  // apiUrl: "http://localhost:8080/api/v1.0",
  headers: (auth = false) => {
    let language = "cs";
    if (window.navigator && window.navigator.language) {
      language = window.navigator.language.split("-")[0] || "cs";
    }
    const token = localStorage.getItem("access_token");
    if (auth) {
      return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "Accept-language": language,
      };
      y;
    } else {
      return {
        "Content-Type": "application/json",
        "Accept-language": language,
      };
    }
  },
};
export default obj;
