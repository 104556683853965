import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    current: "cs",
  },
  theme: {
    themes: {
      dark: {
        primary: "#DBA822",
        secondary: "#1D1D1B",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        grey: "#a3a4a4",
      },
      light: {
        primary: "#DBA822",
        secondary: "#1D1D1B",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        grey: "#a3a4a4",
      },
    },
  },
});
