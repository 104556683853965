import req from './request';

const obj = {
  activateAccount: async (code) => {
    try {
      const { message } = await req.put(
        `auth/account-activation/${code}`,
        false
      );
      return message;
    } catch (error) {
      throw error;
    }
  },
  login: async (data, cb = null) => {
    try {
      const login = await req.post('auth/token', data, false);

      localStorage.setItem('access_token', login.access_token);
      const user = await obj.currentUser();
      return user;
    } catch (e) {
      throw e;
    }
  },
  logout: async () => {
    await req.post('auth/logout', null, true);
    localStorage.removeItem('access_token');
    localStorage.removeItem('building');
    return true;
  },
  currentUser: async () => {
    try {
      const { data } = await req.get('auth/me', true);
      return data;
    } catch (e) {
      throw e;
    }
  },
  sectionChanges: async () => {
    try {
      const building = JSON.parse(localStorage.getItem('building')) || null;
      const { data } = await req.get(
        'auth/sections-changes?building_id=' + building.id,
        true
      );
      return data;
    } catch (e) {
      throw e;
    }
  },

  forgottenPassword: async (email) => {
    try {
      const message = await req.post(
        'auth/forgotten-password',
        { email },
        false
      );
      return message;
    } catch (error) {
      throw error;
    }
  },
  forgottenPasswordLink: async (code, password) => {
    try {
      return await req.post(
        `auth/forgotten-password/${code}`,
        { password },
        false
      );
    } catch (e) {
      throw e;
    }
  },
  handleAutUser: async (store, router) => {
    try {
      if (store) {
        if (!store.getters.isAuthorized) {
          await store.dispatch('getCurrentUser');
        }
      }
    } catch (error) {
      // Unauthorized user
      console.error(error);
      router.push({ name: 'Login' });
      throw error;
    }
  },
  password: async (data) => {
    try {
      const response = await req.put(`auth/password`, data, true);
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default obj;
