<template>
  <v-app>
    <v-main v-if="routeInitialized">
      <Spinner v-if="processing" />
      <template v-if="notBase.includes($route.name)">
        <router-view />
      </template>
      <template v-if="!notBase.includes($route.name)">
        <AdminLayout>
          <router-view />
        </AdminLayout>
      </template>
      <v-snackbar v-model="snackbar">
        {{ snackText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            :color="snackColor"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Zavřít
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import bus from "./plugins/bus";
import AdminLayout from "./components/AdminLayout";
import Spinner from "./components/Spinner";

export default {
  name: "App",
  components: {
    AdminLayout,
    Spinner,
  },
  data: () => ({
    notBase: [
      "Home",
      "Login",
      "ForgottenPassword",
      "ForgottenPasswordCode",
      "AccountActivation",
      "Password",
      "Policies"
    ],
    snackbar: false,
    snackText: "",
    snackColor: "success",
    processing: false,
    routeInitialized: false,
  }),
  mounted() {
    if (this.$route) this.routeInitialized = true;
    if (document) {
      document.title = "MyMD21";
    }
  },
  async created() {
    console.debug("routename", this.$route.name);
    // try {
    //   await this.$store.dispatch("getCurrentUser");
    //   if (
    //     this.notBase.includes(this.$route.name) &&
    //     this.$store.state.user &&
    //     this.$store.state.user.admin
    //   ) {
    //     this.$router.push({ name: "Customers" });
    //   }
    // } catch (e) {
    //   if (
    //     e.response &&
    //     e.response.status == 401 &&
    //     !this.notBase.includes(this.$route.name)
    //   ) {
    //     this.$router.push({ name: "Login" }).catch(() => {});
    //   }
    //   this.$router.push({ name: "Login" }).catch(() => {});
    // }
    bus.$on("snackbar", ({ text, color = "success" }) => {
      this.snackText = text;
      this.snackColor = color;
      this.snackbar = true;
    });
    bus.$on("processing", (state = true) => {
      this.processing = state;
    });
  },
  // watch: {
  //   $route(to, from) {
  //     if (
  //       Object.keys(this.$store.state.user).length != 0 &&
  //       this.notBase.includes(to.name)
  //     ) {
  //       // this.$router.push({ name: "Customers" });
  //     }
  //   },
  // },
};
</script>
<style lang="scss">
@import "./scss/main";
</style>
