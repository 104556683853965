import Vue from "vue";
import Vuex from "vuex";
import User from "../api/authService";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    auth: false,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    isAuthorized(state) {
      return state.auth;
    },
  },
  mutations: {
    setUser(state, payload) {
      return (state.user = payload);
    },
    setAuth(state, payload = true) {
      return (state.auth = payload);
    },
  },
  actions: {
    setUser(context, payload) {
      context.commit("setUser", payload);
      context.commit("setAuth", true);
    },
    async getCurrentUser(context) {
      try {
        const user = await User.currentUser();
        context.commit("setUser", user);
        context.commit("setAuth", true);
      } catch (e) {
        context.commit("setUser", null);
        context.commit("setAuth", false);
        throw e;
      }
    },
  },
  modules: {},
});
