import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Login.vue"),
  },
  {
    path: "/aktivace-uctu/:activationCode",
    name: "AccountActivation",
    component: () =>
      import(
        /* webpackChunkName: "activation" */ "../views/AccountActivation.vue"
      ),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/zapomenute-heslo",
    name: "ForgottenPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgottenpassword" */ "../views/ForgottenPassword.vue"
      ),
  },
  {
    path: "/zapomenute-heslo/:forgottenPasswordCode",
    name: "ForgottenPasswordCode",
    component: () =>
      import(
        /* webpackChunkName: "forgottenpasswordcode" */ "../views/ForgottenPasswordCode.vue"
      ),
  },
  /**
   * Protected routes
   */
  {
    path: "/vyber-stavby",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
  },
  {
    path: "/uzivatele",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Users.vue"),
  },
  {
    path: "/uzivatele/novy",
    name: "UserNew",
    component: () =>
      import(/* webpackChunkName: "usernew" */ "../views/UserNew.vue"),
  },
  {
    path: "/uzivatele/:userId",
    name: "UserDetail",
    component: () =>
      import(/* webpackChunkName: "userdetail" */ "../views/UserDetail.vue"),
  },
  {
    path: "/sprava-staveb",
    name: "Buildings",
    component: () =>
      import(/* webpackChunkName: "buildings" */ "../views/Buildings.vue"),
  },
  {
    path: "/sprava-staveb/nova",
    name: "BuildingsNew",
    component: () =>
      import(
        /* webpackChunkName: "buildings_new" */ "../views/BuildingsNew.vue"
      ),
  },
  {
    path: "/sprava-staveb/:id",
    name: "BuildingsDetail",
    component: () =>
      import(
        /* webpackChunkName: "buildings_detail" */ "../views/BuildingsDetail.vue"
      ),
  },
  {
    path: "/zpravy",
    name: "Messages",
    component: () =>
      import(/* webpackChunkName: "messages" */ "../views/Messages.vue"),
  },
  {
    path: "/zpravy/:id",
    name: "MessagesDetail",
    component: () =>
      import(
        /* webpackChunkName: "messagesdetail" */ "../views/MessagesDetail.vue"
      ),
  },
  {
    path: "/profil",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
  },
  {
    path: "/zmena-hesla",
    name: "Password",
    component: () =>
      import(/* webpackChunkName: "password" */ "../views/ChangePassword.vue"),
  },
  {
    path: "/ochrana-osobnich-udaju",
    name: "Privacy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue"),
  },
  {
    path: "/vop",
    name: "Policies",
    component: () =>
      import(/* webpackChunkName: "Policies" */ "../views/Policies.vue"),
  },
  {
    path: "/kontakty",
    name: "Contacts",
    component: () =>
      import(/* webpackChunkName: "contacts" */ "../views/Contacts.vue"),
  },
  {
    path: "/timeline",
    name: "Timeline",
    component: () =>
      import(/* webpackChunkName: "timeline" */ "../views/Timeline.vue"),
  },
  {
    path: "/timeline/nova",
    name: "Timeline-new",
    component: () =>
      import(/* webpackChunkName: "timeline_new" */ "../views/TimelineNew.vue"),
  },
  {
    path: "/timeline/:id",
    name: "Timeline-detail",
    component: () =>
      import(
        /* webpackChunkName: "timeline_detail" */ "../views/TimelineDetail.vue"
      ),
  },
  {
    path: "/video-zive",
    name: "Videoonline",
    component: () =>
      import(/* webpackChunkName: "videoonline" */ "../views/Livevideo.vue"),
  },
  {
    path: "/projektovani",
    name: "Projecting",
    component: () =>
      import(/* webpackChunkName: "projecting" */ "../views/Projecting.vue"),
  },
  {
    path: "/projektovani/nova",
    name: "Projecting-new",
    component: () =>
      import(
        /* webpackChunkName: "projecting_new" */ "../views/ProjectingNew.vue"
      ),
  },
  {
    path: "/projektovani/:id",
    name: "Projecting-detail",
    component: () =>
      import(
        /* webpackChunkName: "projecting_detail" */ "../views/ProjectingDetail.vue"
      ),
  },
  {
    path: "/projektovani/:id/dokument",
    name: "Projecting-document-new",
    component: () =>
      import(
        /* webpackChunkName: "projecting_document_new" */ "../views/ProjectingDocumentNew.vue"
      ),
  },
  {
    path: "/projektovani/:id/dokument/:documentId",
    name: "Projecting-document-detail",
    component: () =>
      import(
        /* webpackChunkName: "projecting_document_detail" */ "../views/ProjectingDocumentDetail.vue"
      ),
  },
  {
    path: "/projektovani/:id/slozka/nova",
    name: "ProjectingSubdirectory-new",
    component: () =>
      import(
        /* webpackChunkName: "projecting_new_subdirectory" */ "../views/ProjectingSubdirectoryNew.vue"
      ),
  },
  {
    path: "/projektovani/:id/slozka/:subId",
    name: "ProjectingSubdirectory-detail",
    component: () =>
      import(
        /* webpackChunkName: "projecting_detail_subdirectory" */ "../views/ProjectingSubdirectoryDetail.vue"
      ),
  },
  {
    path: "/projektovani/:id/slozka/:subId/dokument",
    name: "ProjectingSubdirectory-document-new",
    component: () =>
      import(
        /* webpackChunkName: "projecting_subdirectory_document_new" */ "../views/ProjectingSubdirectoryDocumentNew.vue"
      ),
  },
  {
    path: "/projektovani/:id/slozka/:subId/dokument/:documentId",
    name: "ProjectingSubdirectory-document-detail",
    component: () =>
      import(
        /* webpackChunkName: "projecting_subdirectory_document_detail" */ "../views/ProjectingSubdirectoryDocumentDetail.vue"
      ),
  },
  {
    path: "/stavebni-denik",
    name: "ConstructionDiary",
    component: () =>
      import(
        /* webpackChunkName: "construction_diary" */ "../views/ConstructionDiary.vue"
      ),
  },
  {
    path: "/stavebni-denik/novy",
    name: "ConstructionDiary-new",
    component: () =>
      import(
        /* webpackChunkName: "construction_diary_new" */ "../views/ConstructionDiaryNew.vue"
      ),
  },
  {
    path: "/stavebni-denik/:id",
    name: "ConstructionDiary-detail",
    component: () =>
      import(
        /* webpackChunkName: "construction_diary_detail" */ "../views/ConstructionDiaryDetail.vue"
      ),
  },
  {
    path: "/fotogalerie",
    name: "PhotoGallery",
    component: () =>
      import(
        /* webpackChunkName: "photogallery" */ "../views/PhotoGallery.vue"
      ),
  },
  {
    path: "/fotogalerie/:id",
    name: "PhotoGallery-detail",
    component: () =>
      import(
        /* webpackChunkName: "photogallery_detail" */ "../views/PhotoGalleryDetail.vue"
      ),
  },
  {
    path: "/vizualizace",
    name: "Visualization",
    component: () =>
      import(
        /* webpackChunkName: "visualization" */ "../views/Visualization.vue"
      ),
  },
  {
    path: "/vizualizace/nova",
    name: "Visualization-new",
    component: () =>
      import(
        /* webpackChunkName: "visualization_new" */ "../views/VisualizationNew.vue"
      ),
  },
  {
    path: "/vizualizace/:id",
    name: "Visualization-detail",
    component: () =>
      import(
        /* webpackChunkName: "visualization_detail" */ "../views/VisualizationDetail.vue"
      ),
  },
  {
    path: "/karta-stavby",
    name: "ManageSelectedBuilding",
    component: () =>
      import(
        /* webpackChunkName: "manage_selected_building" */ "../views/ManageSelectedBuilding.vue"
      ),
  },
  {
    path: "/smlouvy",
    name: "Contracts",
    component: () =>
      import(/* webpackChunkName: "Contracts" */ "../views/Contracts.vue"),
  },
  {
    path: "/smlouvy/nova",
    name: "Contracts-new",
    component: () =>
      import(
        /* webpackChunkName: "Contracts_new" */ "../views/ContractsNew.vue"
      ),
  },
  {
    path: "/smlouvy/:id",
    name: "Contracts-detail",
    component: () =>
      import(
        /* webpackChunkName: "contracts_detail" */ "../views/ContractsDetail.vue"
      ),
  },
  {
    path: "/platby",
    name: "Payments",
    component: () =>
      import(/* webpackChunkName: "payments" */ "../views/Payments.vue"),
  },
  {
    path: "/platby/nova",
    name: "Payments-new",
    component: () =>
      import(/* webpackChunkName: "Payments_new" */ "../views/PaymentsNew.vue"),
  },
  {
    path: "/platby/:id",
    name: "Payments-detail",
    component: () =>
      import(
        /* webpackChunkName: "Payments_detail" */ "../views/PaymentsDetail.vue"
      ),
  },
  {
    path: "/reklamace",
    name: "Complaints",
    component: () =>
      import(/* webpackChunkName: "Complaints" */ "../views/Complaints.vue"),
  },
  {
    path: "/reklamace/nova",
    name: "Complaints-new",
    component: () =>
      import(/* webpackChunkName: "Complaints_new" */ "../views/ComplaintsNew.vue"),
  },
  {
    path: "/reklamace/:id",
    name: "Complaints-detail",
    component: () =>
      import(
        /* webpackChunkName: "Complaints_detail" */ "../views/ComplaintsDetail.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
