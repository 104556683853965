import bus from "../plugins/bus";

export default function (error) {
  if (
    error.response &&
    error.response.data &&
    error.response.data.error.message
  ) {
    bus.$emit("snackbar", {
      text: error.response.data.error.message,
      color: "error",
    });
  }
}
