<template>
  <v-app id="inspire" v-if="userLoaded">
    <v-navigation-drawer v-model="drawer" app>
      <!-- app clipped -->
      <div
        v-if="selectedBuilding"
        class="selected-building-wrapper"
        :style="`height: 100px;`"
      >
        <span class="font-weight-bold ellipsis-text"
          >{{ selectedBuilding.name }} </span
        >
        <!-- {{ selectedBuilding.city }} -->
        <v-row class="mt-1">
          <v-col>
            <v-btn
              x-small
              tile
              color="primary"
              class="mr-1"
              height="30"
              @click="changeBuilding"
              ><v-icon :style="zmenit" class="mymd-icon-small mr-1"></v-icon>
              Změnit</v-btn
            >
            <v-btn
              x-small
              tile
              color="primary"
              height="30"
              @click="(drawer = false), (smsDialog = true)"
              v-if="user.role == 'SUPERUSER' || user.role == 'PROJECT_MANAGER'"
              ><v-icon :style="sms" class="mymd-icon-small mr-1"></v-icon>
              Notifikační sms</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <v-list dense :class="{ 'v-list-selected': selectedBuilding }">
        <v-list-item link :to="{ name: 'Videoonline' }" v-if="selectedBuilding">
          <v-list-item-action>
            <v-icon class="mymd-icon top-4" :style="videoonline"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Video živě</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{ name: 'Timeline' }" v-if="selectedBuilding">
          <v-list-item-action>
            <div
              class="section-changed"
              v-if="sections.includes('TIMELINE')"
            ></div>
            <v-icon :class="`mymd-icon`" :style="timeline"> </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Timeline</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{ name: 'Projecting' }" v-if="selectedBuilding">
          <v-list-item-action>
            <div
              class="section-changed"
              v-if="sections.includes('PROJECTING')"
            ></div>
            <v-icon class="mymd-icon" :style="projecting"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Projektování</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'Visualization' }"
          v-if="selectedBuilding"
        >
          <v-list-item-action>
            <div
              class="section-changed"
              v-if="sections.includes('VISUALIZATION')"
              style="top: 12px"
            ></div>
            <v-icon class="mymd-icon" :style="vizualizace"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Vizualizace</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'ConstructionDiary' }"
          v-if="selectedBuilding"
        >
          <v-list-item-action>
            <div
              class="section-changed"
              v-if="sections.includes('CONSTRUCTION_DIARY')"
            ></div>
            <v-icon class="mymd-icon" :style="stavebniDenik"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Stavební deník</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'PhotoGallery' }"
          v-if="selectedBuilding"
        >
          <v-list-item-action>
            <v-icon class="mymd-icon" :style="fotogalerie"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Fotogalerie</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{ name: 'Payments' }" v-if="selectedBuilding">
          <v-list-item-action>
            <div
              class="section-changed"
              v-if="sections.includes('PAYMENTS')"
            ></div>
            <v-icon class="mymd-icon" :style="platby"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Platby</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{ name: 'Dashboard' }" v-if="!selectedBuilding">
          <v-list-item-action>
            <v-icon class="mymd-icon top-4" :style="vyberStavby"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Výběr stavby</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{ name: 'Contracts' }" v-if="selectedBuilding">
          <v-list-item-action>
            <div
              class="section-changed"
              v-if="sections.includes('CONTRACTS')"
            ></div>
            <v-icon class="mymd-icon" :style="smlouvy"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Smlouvy</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{ name: 'Messages' }" v-if="selectedBuilding">
          <v-list-item-action>
            <div
              class="section-changed"
              style="top: 11px"
              v-if="sections.includes('MESSAGES')"
            ></div>
            <v-icon class="mymd-icon top-4" :style="zpravy"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Zprávy</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{ name: 'Contacts' }" v-if="selectedBuilding">
          <v-list-item-action>
            <v-icon class="mymd-icon" :style="kontakty"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Kontakty</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'ManageSelectedBuilding' }"
          v-if="selectedBuilding"
        >
          <v-list-item-action>
            <div
              class="section-changed"
              v-if="sections.includes('BUILDING')"
            ></div>
            <v-icon class="mymd-icon" :style="kartastavby"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Karta stavby</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link :to="{ name: 'Complaints' }" v-if="selectedBuilding">
          <v-list-item-action>
            <div
              class="section-changed"
              v-if="sections.includes('COMPLAINTS')"
            ></div>
            <v-icon class="mymd-icon" :style="reklamace"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Reklamace</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'Users' }"
          v-if="user.role == 'SUPERUSER'"
        >
          <v-list-item-action>
            <v-icon class="mymd-icon" :style="spravaUzivatelu"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Správa uživatelů</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="{ name: 'Buildings' }"
          v-if="user.role == 'SUPERUSER'"
        >
          <v-list-item-action>
            <v-icon class="mymd-icon top-4" :style="vyberStavby"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Správa staveb</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-divider></v-divider>
        <v-list
          class="scrollable-bottom-list"
          id="bottomNavigationList"
          style="background: #f8f8f8"
          data-before-bottom-nav=""
          data-bottom-nav-opacity="1"
          min-height="240"
        >
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col cols="2" class="mr-1">
                  <div
                    style="
                      width: 36px;
                      height: 36px;
                      color: white;
                      background: #dba822;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <span class="font-weight-bold">
                      {{
                        $store.state.user.first_name
                          ? $store.state.user.first_name[0]
                          : ""
                      }}
                    </span>
                  </div>
                </v-col>
                <v-col>
                  <span
                    class="font-weight-bold"
                    style="text-transform: uppercase; font-size: 14px"
                  >
                    {{ $store.state.user.first_name }}
                    {{ $store.state.user.last_name }}
                  </span>
                  <v-row>
                    <v-col>
                      <span class="font-weight-thin" style="font-size: 11px">{{
                        $store.state.user.role_loc
                      }}</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <v-btn
                style="font-size: 11px"
                class="navigation__logout font-weight-medium"
                color="secondary"
                text
                @click="$router.push({ name: 'Profile' })"
              >
                Profil
              </v-btn>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-btn
                style="font-size: 11px"
                class="navigation__logout font-weight-medium"
                color="secondary"
                text
                @click="$router.push({ name: 'Password' })"
              >
                Změna hesla
              </v-btn>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-btn
                style="font-size: 11px"
                class="navigation__logout"
                color="error"
                text
                @click="logout"
              >
                Odhlásit
              </v-btn>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="mt-3">
            <v-list-item-content>
              <v-btn
                style="font-size: 11px; color: #dba822"
                class="navigation__logout"
                text
                @click="$router.push({ name: 'Privacy' })"
              >
                Ochrana osobních údajů
              </v-btn>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content
              ><span
                style="font-size: 11px; text-align: center"
                class="col-secondary-2"
                >Vytvořilo
                <a
                  class="col-secondary-1 text-no-underline"
                  href="https://peko-studio.cz"
                  >Peko Studio s.r.o.</a
                ></span
              ></v-list-item-content
            >
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-right
      color="white"
      height="100"
      id="appbarIdentifier"
    >
      <!-- :height="!scrolled ? 100 : 70" -->
      <div class="left-second-rects" :style="second"></div>
      <div class="left-first-rect" :style="first"></div>
      <div class="right-first-rect" :style="rightFirst"></div>
      <div class="right-second-rect" :style="rightSecond"></div>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"
        ><v-icon class="ml-12" v-if="!drawer"> mdi-menu</v-icon>
        <v-icon v-if="drawer" class="ml-12">mdi-close</v-icon>
        <span>MENU</span>
      </v-app-bar-nav-icon>
      <v-toolbar-title style="width: 100%">
        <div
          class="text-center app-bar-logo"
          :style="`right:${this.logoPos}px;top:26px`"
        >
          <!-- ${scrolled ? 16 : 26} -->
          <img
            src="../assets/logo.svg"
            :style="`height: 38px; position: relative; top: 4px`"
            alt=""
          />
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-if="size >= 768">
        <div
          v-if="selectedBuilding"
          style="
            background: #1d1d1b;
            color: white;
            height: 80px;
            font-size: 12px;
          "
          class="pa-4 d-flex font-weight-bold flex-column text-right"
        >
          <span class="ellipsis-text">{{ selectedBuilding.name }}</span>
          <span class="font-weight-medium">{{ selectedBuilding.city }}</span>
        </div>
        <div
          style="
            background: #dba822;
            color: white;
            font-size: 12px;
            white-space: nowrap;
            text-transform: uppercase;
            height: 80px;
          "
          class="pa-4 d-flex font-weight-bold flex-column"
        >
          <span
            >{{ $store.state.user.first_name }}
            {{ $store.state.user.last_name }}</span
          >
          <span
            class="d-block font-weight-medium"
            style="text-transform: uppercase"
            >{{ $store.state.user.role_loc }}</span
          >
        </div>
      </template>

      <v-row justify="center">
        <v-dialog
          v-model="smsDialog"
          :max-width="450"
          persistent
          class="rounded-0"
        >
          <v-card>
            <v-card-title class="headline"
              >Odeslat notifikační sms</v-card-title
            >
            <v-card-subtitle
              >Opravdu si přejete odeslat notifikační sms ?</v-card-subtitle
            >
            <v-card-text>
              <v-row> </v-row>
              <v-row class="mt-6">
                <v-col class="text-center">
                  <v-btn
                    color="primary"
                    small
                    tile
                    width="100%"
                    @click="sendSms"
                    >Odeslat</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-center">
                  <v-btn small text @click="smsDialog = false" width="100%"
                    >Zrušit</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-app-bar>

    <slot></slot>
  </v-app>
</template>

<script>
import Auth from "../api/authService";
import bus from "../plugins/bus";
import notificationService from "../api/notificationService";
import errorHandler from "../api/errorHandler";
export default {
  data() {
    return {
      smsDialog: false,
      scrolled: false,
      drawer: false,
      userLoaded: false,
      logoPos: null,
      size: null,
      selectedBuilding: null,
      sections: [],
    };
  },
  async created() {
    this.getSelectedBuilding();
    if (this.selectedBuilding) await this.getSectionChanges();
    bus.$on("refresh_sections", async () => {
      await this.getSectionChanges();
    });
  },
  methods: {
    async getSectionChanges() {
      try {
        const sections = await Auth.sectionChanges();
        console.debug("sections", sections);
        this.sections = sections;
      } catch (error) {
        console.error(error);
      }
    },
    async sendSms() {
      try {
        bus.$emit("processing");
        await notificationService.systemNotification();
        bus.$emit("processing", false);
        this.smsDialog = false;
      } catch (error) {
        bus.$emit("processing", false);
        console.error(error);
        errorHandler(error);
      }
    },
    changeBuilding() {
      localStorage.removeItem("building");
      this.getSelectedBuilding();
      this.$router.push({ name: "Dashboard" });
    },
    getSelectedBuilding() {
      this.selectedBuilding =
        JSON.parse(localStorage.getItem("building")) || null;
    },
    async logout() {
      await Auth.logout();
      this.$router.push({ name: "Login" });
    },
    handleScroll(e) {
      if (window.scrollY > 100) {
        this.scrolled = true;
        return;
      }
      this.scrolled = false;
      return;
    },
    handleResize() {
      if (document) {
        this.size = window.innerWidth;
        this.logoPos = (window.innerWidth - 127) / 2;
      }
    },
    handleBottomNavScroll(e) {
      if (
        e.target.scrollHeight - e.target.scrollTop ===
        e.target.clientHeight
      ) {
        // e.target.setAttribute("data-bottom-nav-opacity", 0);
        e.target.classList.add("scrollable-bottom-list--finished");
      } else {
        e.target.classList.remove("scrollable-bottom-list--finished");
      }
    },
  },
  async mounted() {
    window.addEventListener("scroll", this.handleScroll);
    if (!this.$store.state.user) {
      try {
        await this.$store.dispatch("getCurrentUser");
        this.userLoaded = true;
      } catch (error) {
        // this.$router.push({ name: "Login" });
        this.userLoaded = false;
      }
    } else {
      this.userLoaded = true;
    }

    if (document) {
      this.size = window.innerWidth;
      this.logoPos = (window.innerWidth - 127) / 2;
      this.$nextTick(() => {
        const bottomNavEl = document.getElementById("bottomNavigationList");
        bottomNavEl.addEventListener("scroll", this.handleBottomNavScroll);
      });
    }
    window.addEventListener("resize", this.handleResize);

    bus.$on("select-building", (building) => {
      this.getSelectedBuilding();
    });

    // bus.$on("get-selected-building")
    this.getSelectedBuilding();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    const bottomNavEl = document.getElementById("bottomNavigationList");
    bottomNavEl.removeEventListener("scroll", this.handleBottomNavScroll);
    bus.$off("refresh_sections");
    console.debug("Destroyed");
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    first() {
      return {
        backgroundImage: `url('${require("@/assets/first_rect.svg")}')`,
      };
    },
    rightFirst() {
      return {
        backgroundImage: `url('${require("@/assets/first_right.svg")}')`,
      };
    },
    rightSecond() {
      return {
        backgroundImage: `url('${require("@/assets/second_right.svg")}')`,
      };
    },
    second() {
      return {
        backgroundImage: `url('${require("@/assets/second_rects.svg")}')`,
      };
    },
    vyberStavby() {
      return {
        backgroundImage: `url(${require("@/assets/sprava-staveb.svg")} !important`,
      };
    },
    spravaUzivatelu() {
      return {
        backgroundImage: `url(${require("@/assets/sprava-uzivatelu.svg")} !important`,
      };
    },
    zmenit() {
      return {
        backgroundImage: `url(${require("@/assets/zmenit.svg")} !important`,
      };
    },
    sms() {
      return {
        backgroundImage: `url(${require("@/assets/sms.svg")} !important`,
      };
    },
    zpravy() {
      return {
        backgroundImage: `url(${require("@/assets/zpravy.svg")} !important`,
      };
    },
    kontakty() {
      return {
        backgroundImage: `url(${require("@/assets/kontakty.svg")} !important`,
      };
    },
    timeline() {
      return {
        backgroundImage: `url(${require("@/assets/timeline.svg")} !important`,
      };
    },
    videoonline() {
      return {
        backgroundImage: `url(${require("@/assets/videoonline.svg")} !important`,
      };
    },
    projecting() {
      return {
        backgroundImage: `url(${require("@/assets/projektovani.svg")} !important`,
      };
    },
    stavebniDenik() {
      return {
        backgroundImage: `url(${require("@/assets/stavebnidenik.svg")} !important`,
      };
    },
    fotogalerie() {
      return {
        backgroundImage: `url(${require("@/assets/fotogalerie.svg")} !important`,
      };
    },
    vizualizace() {
      return {
        backgroundImage: `url(${require("@/assets/vizualizace.svg")} !important`,
      };
    },
    kartastavby() {
      return {
        backgroundImage: `url(${require("@/assets/kartastavby.svg")} !important`,
      };
    },
    smlouvy() {
      return {
        backgroundImage: `url(${require("@/assets/smlouvy.svg")} !important`,
      };
    },
    platby() {
      return {
        backgroundImage: `url(${require("@/assets/platby.svg")} !important`,
      };
    },
    reklamace() {
      return {
        backgroundImage: `url(${require("@/assets/reklamace.svg")} !important`,
      };
    },
  },
  watch: {
    async $route() {
      // await setTimeout(async () => {
      //   await this.getSectionChanges();
      // }, 250);
    },
  },
};
</script>
