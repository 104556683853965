import req from "./request";

const obj = {
  systemNotification: async () => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      return await req.post("sms?building_id=" + building.id, null, true);
    } catch (e) {
      throw e;
    }
  },
};

export default obj;
